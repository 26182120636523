var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"allow",rawName:"v-allow:auth",value:('package.read'),expression:"'package.read'",arg:"auth"}],staticClass:"min-h-full relative max-h-full flex flex-col text-base-content"},[_c('sub-header',{staticClass:"mb-4",attrs:{"showBtn":_vm.showReplaceWorkflow,"handleClick":_vm.handleReplaceWorkflow,"buttonText":"Replace Workflow","backButton":true,"backRedirectionUrl":"/packages","customCrumbLists":_vm.customBreadCumbs,"handleBackSection":_vm.setEditModeUnlock}}),_c('div',{directives:[{name:"allow",rawName:"v-allow:auth",value:('package.edit'),expression:"'package.edit'",arg:"auth"}],staticClass:"w-full h-full overflow-hidden"},[_c('div',{staticClass:"flex h-full gap-4 flex-1 overflow-hidden"},[_c('div',{staticClass:"w-2/12 rounded-lg self-start bg-card-bg shadow flex flex-col gap-x-4 overflow-y-auto scroll-bar"},_vm._l((_vm.navigations),function(nav,idx){return _c('div',{key:idx,staticClass:"px-4 py-3 transition-all relative duration-200 items-center text-sm font-medium flex gap-3 cursor-pointer",class:{
                        'bg-primary-300 text-white-text': _vm.currentTab === nav.nav,
                        'hover:bg-gray-100 hover:text-base-content-600': _vm.currentTab !== nav.nav,
                        'border-t border-0' : idx != 0
                    },on:{"click":function($event){return _vm.handleCurrentTab(nav)}}},[_c('font-awesome-icon',{attrs:{"icon":[nav.iconPrefix || 'fa', nav.icon]}}),_vm._v(" "+_vm._s(nav.title)+" ")],1)}),0),_c('div',{staticClass:"flex flex-col flex-1 gap-3 w-full h-full overflow-hidden"},[(_vm.currentTabConfig.tab.entityTypeTabs && _vm.entityTypeTabs.length)?_c('Tabs',{staticClass:"entity-type-tabs border-b w-full",attrs:{"tabs":_vm.entityTypeTabs,"no-content":""},model:{value:(_vm.selectedEntityType),callback:function ($$v) {_vm.selectedEntityType=$$v},expression:"selectedEntityType"}}):_vm._e(),(_vm.getPackage)?_c('UISection',{class:{
                        'description_height': _vm.currentTab === 'description',
                        'overflow-y-hidden': _vm.currentTab !== 'description',
                    },scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"text-white-text"},[_vm._v("Package: ")]),_c('span',{staticClass:"font-normal text-white-text"},[_vm._v(_vm._s(_vm.originPackageData.package_name))])]},proxy:true}],null,false,2730547467)},[_c('div',{staticClass:"w-full scroll-bar",staticStyle:{"max-height":"72vh"}}),_c(_vm.currentTabConfig.component.el,_vm._b({ref:_vm.currentTab,tag:"component"},'component',_vm.currentTabConfig.component.props,false))],1):_c('div',{staticClass:"flex flex-1 bg-card-bg p-3 h-full overflow-y-scroll scroll-bar justify-center items-center"},[_vm._v(" No Options Selected ")])],1)])]),_c('modal-confirm',{ref:"confirm-popup",attrs:{"title":"Are you sure?","message":"Unsaved data will be lost. Please confirm to continue without saving."}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }